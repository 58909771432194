import "./IconLabeled.css"

const IconLabeled = ({label, src, alt}) => {
    return (
        <div className="IconLabeledWrapper">
            <img
                className="IconStyled"
                src={src} 
                alt={alt}/>
            <span className="LabelStyled">
                {label}
            </span>
        </div>
    )
}

export default IconLabeled;