import React from 'react'
import './Drawer.css'
import { Link } from 'react-router-dom';
import DateImage from '../../../images/date-image.png'

const Drawer = ({isVisible, onClickOutside}) => {
    if (!isVisible) return null;

    const scrollToTop = () => {
      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth'
      }); 
    }

    const drawerHeader = () => {
      return (
        <div className='DrawerHeader'>
          <img src={DateImage} className='DrawerHeaderImage'/>
          <span className='DrawerHeaderText'>Karolina & Kuba</span>
        </div>
      );
    }

    const drawerButton = (label, isEnabled = true) => (
      <button className='DrawerButton' disabled={!isEnabled}>
        <div className='DrawerButtonContent'>
          <span className={isEnabled ? 'DrawerButtonText' : 'DrawerButtonTextDisabled'}>
            {label}
          </span>
          {!isEnabled ? <span className='DrawerButtonSubText'>{"(Dostępne wkrótce)"}</span> : null}
        </div>
      </button>
    )

    const linkRow = (label, linkTo, isEnabled = true) => (
      <Link 
        className='LinkRow' 
        to={linkTo} 
        onClick={(e) => {
              scrollToTop();
              if (onClickOutside) {
                onClickOutside();
              }
            }
          }>
          {drawerButton(label, isEnabled)}
      </Link>
    )

    return (
      <div className="DrawerOverlay">
        <div className='DrawerOutside' onClick={onClickOutside}>
        </div>
        <div className='DrawerContent'>
          <div className='DrawerScrollableContent'>
            {drawerHeader()}
            {linkRow('Strona główna',`/`)}
            {linkRow('Zakwaterowanie', `/accomodation`)}
            {linkRow('Ceremonia', `/wedding`)}
            {linkRow('Wesele', `/party`)}
            {linkRow('RSVP', `/rsvp`)}
            {linkRow('Kontakt', `/contact`)}
          </div>
        </div>
      </div>
    );
}

export default Drawer;