import Arrow from '../../images/arrow-right.svg'
import Book from '../../images/book.svg'
import Cash from '../../images/cash.svg'
import Cocktail from '../../images/cocktail.svg'
import CoffeeBag from '../../images/coffee-bag.svg'
import Disallowed from '../../images/disallowed.svg'
import DiscoBall from '../../images/disco-ball.svg'
import Dishes from '../../images/dishes.svg'
import Flowers from '../../images/flowers.svg'
import Gift from '../../images/gift.svg'
import Lotto from '../../images/lotto.svg'
import PhotoCamera from '../../images/photo-camera.svg'
import PetFood from '../../images/pet-food.svg'
import Sofa from '../../images/sofa.svg'
import Tick from '../../images/tick-icon.svg'
import WineGlass from '../../images/wine-glass.svg'

export const Icons = {
    arrow: Arrow,
    book: Book,
    cash: Cash,
    cocktail: Cocktail,
    coffeeBag: CoffeeBag,
    disallowed: Disallowed,
    discoBall: DiscoBall,
    dishes: Dishes,
    flowers: Flowers,
    gift: Gift,
    lotto: Lotto,
    photoCamera: PhotoCamera,
    petFood: PetFood,
    sofa: Sofa,
    tick: Tick,
    wineGlass: WineGlass,
}