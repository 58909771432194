import React from "react";
import './Button.css'

const Button = ({className, style, isEnabled = true, onClick, type, children}) => {

    const buildClassName = () => `StyledButton ButtonColors ${className ? className : ""}`

    return (
        <button 
            className={buildClassName()} 
            onClick={onClick}
            disabled={!isEnabled}
            style={style}
            type={type}>
                {children}
        </button>
    );
}

export default Button;