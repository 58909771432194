import React from "react";
import './AccomodationPage.css'
import Heading from "../../modules/heading/Heading";
import Paragraph from "../../modules/paragraph/Paragraph";
import EmbeddedMap from "../../modules/map/EmbeddedMap";
import { Images } from "../../modules/image/Images";
import FormattedMessage from "../../modules/resolve/MessageFromatter";
import Footer from "../../modules/footer/Footer";
import KoleoLogo from "../../images/koleo_logo.png"

const AccomodationPage = () => {
    return (
        <div className='AccomodationPage'>
        <Heading 
            headingImage={Images.accomodation}
            headingText={"Zakwaterowanie"}/>
            <div className="PageContent">
                <div className="Section DarkerBackground SlideAppearLeft">
                    <span className="SubHeader Handwrite"><FormattedMessage messageKey="accomodation.section.village.header"/></span>
                    <span className="ContentText KeptInTheMiddle"><FormattedMessage messageKey="accomodation.section.village.content"/></span>
                </div>
                <div className="Section SlideAppearRight">
                    <span className="SubHeader Handwrite">Lokalizacja</span>
                    <EmbeddedMap className="Padding5px" localizationKey={'accomodation'} />
                </div>
                <div className="Section DarkerBackground SlideAppearLeft">
                    <span className="SubHeader Handwrite"><FormattedMessage messageKey="accomodation.section.house.header"/></span>
                    <span className="ContentText KeptInTheMiddle"><FormattedMessage messageKey="accomodation.section.house.content"/></span>
                </div>
                <div className="Section SlideAppearRight">
                    <span className="SubHeader Handwrite"><FormattedMessage messageKey="accomodation.section.alternative.header"/></span>
                    <span className="ContentText KeptInTheMiddle"><FormattedMessage messageKey="accomodation.section.alternative.content"/></span>
                    <a 
                        href="https://koleo.pl/rozklad-pkp/wroclaw-glowny/wierzchowice/11-10-2024_09:00/all/all" 
                        target= "_blank"
                        rel="noopener">
                        <img src={KoleoLogo} className="LogoImg TintBlack"/>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AccomodationPage;