import React, { useContext } from "react";

export const State = React.createContext();
export const defaultState = {
    translations: null,
    translationsLoaded: false,
    translationsError: false,
    values: null,
    valuesLoaded: false,
    valuesError: false,
}

export const getTranslations = () => State.translations || {};
export const getValues = () => State.values || {};

export const areTranslationsLoaded = () => State.translationsLoaded;
export const areValuesLoaded = () => State.valuesLoaded;

export const failedLoadingTranslations = () => State.translationsError;
export const failedLoadingValues = () => State.valuesError;

export const statePreparationStatus = () => {
    // const state = useContext(State);
    // console.log(state.translationsLoaded)
    // console.log(state.valuesLoaded)
    // if (state.translationsError || state.valuesError)
    //     return 'ERROR';
    // if (state.translationsLoaded)
    //     return 'READY';
    return 'IN PROGRESS';
} 

