import { retrieveS3FileAsText } from "./S3FileRetriever"

export async function loadResources(state, onFinish, onError) {
    try {
        await retrieveS3FileAsText({
            filePath: 'protected/resources/strings.json', 
            onFetch: (file) => {
                state.translations = JSON.parse(file);
                state.translationsLoaded = true;
                state.translationsFailed = false;
            },
            onError: () => {
                state.translationsLoaded = false;
                state.translationsFailed = true;
            }
        });
        await retrieveS3FileAsText({
            filePath: 'protected/resources/values.json', 
            onFetch: (file) => {
                state.values = JSON.parse(file);
                state.valuesLoaded = true;
                state.valuesFailed = false;
            },
            onError: () => {
                state.valuesLoaded = false;
                state.valuesFailed = true;
            }
        });
        if (onFinish)
            onFinish();
    } catch(error) {
        if (onError)
            onError();
    }
}