import React from "react";
import './HomePage.css'
import Timer from "../../modules/timer/Timer";
import moment from "moment";
import Heading from "../../modules/heading/Heading";
import { Images } from "../../modules/image/Images"
import Footer from "../../modules/footer/Footer";
import Button from "../../modules/button/Button";
import { Link } from 'react-router-dom';
import ProgressiveImg from "../../modules/image/ProgressiveImg"
import Separator from "../../modules/separator/Separator";
import Schedule from "../../modules/schedule/Schedule";

const HomePage = () => {
    const scrollToTop = () => {
        window.scrollTo({ 
          top: 0,  
          behavior: 'smooth'
        }); 
      }

    const renderOnFinish = () => {
        return (
            <div className="Section DarkerBackground AppearLeft">
                <span className="SubHeader Handwrite">Nadszedł czas!</span>
                <span className="ContentText KeptInTheMiddle">Mamy nadzieje że będziecie mieli wiele wspaniałych wspomnień ze wspólnej zabawy.</span>
                <Separator height={'20px'}/>
                {linkButton("/share", "Podziel się wspomnieniami")}
            </div>
        );
    }

    const renderTimerWrapper = (children) => {
        return (
            <div className="Section DarkerBackground AppearLeft">
                <div className="TimerElementWrapper">
                    <span className="TimerText Handwrite">Pozostało</span>
                    {children}
                    <span className="TimerText Handwrite">Do ślubu!</span>
                </div>
            </div>
        );
    }

    const linkButton = (linkTo, children, className) => (
        <Link 
          to={linkTo} 
          onClick={scrollToTop}>
            <Button className={className}>{children}</Button>
        </Link>
    )

    return (
        <div className='HomePage'>
            <Heading 
                imageClassName={"HomePageHeadingImage"}
                headingImage={Images.lnkCats01}/>
            <div className="PageContent">
                <div className="SaveTheDateWrapper AppearTop">
                    <span className="SaveTheDateText Handwrite">Save the date!</span>
                    <span className="Date">12.10.2024</span>
                </div>
                <Timer 
                    endTimestamp={moment("2024-10-12 15:00:00")} 
                    timerWrapper={renderTimerWrapper}
                    elementOnFinish={renderOnFinish()}/>
                <div className="Section AppearRight">
                    <span className="SubHeader Handwrite">Drodzy goście!</span>
                    <span className="ContentText KeptInTheMiddle">Tu znajdziecie najważniejsze informacje odnośnie wesela - daty, lokalizacje, mapki, a także potwierdzicie swoją obecność na naszej uroczystości. Już nie możemy doczekać się spotkania z Wami!</span>
                </div>
                <div className="Section DarkerBackground AppearLeft">
                    <span className="SubHeader Handwrite">Potwierdź swoją obecność</span>
                    {linkButton('/rsvp', "Przejdź do formularza")}
                </div>
                <div className="Section AppearRight">
                    <span className="SubHeader Handwrite">Harmonogram</span>
                    <Schedule scheduleKey="schedule.general"/>
                </div>
                <div className="Section DarkerBackground AppearLeft">
                    <ProgressiveImg 
                        imageClassName="ImageMax600px" 
                        imageKey={Images.flowersAndLemons.key}
                        placeholderSrc={Images.flowersAndLemons.placeholderSrc}/>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default HomePage;