import React from "react";
import "./Paragraph.css"
import ProgressiveImg from "../image/ProgressiveImg";

const Paragraph = ({paragraphContent, image, imageSize, imageStyle, isReverse, paragraphClassName}) => {

    var imageBaseStyle = {
        maxHeight: imageSize ? imageSize.height : undefined,
        maxWidth: imageSize ? imageSize.width : undefined,
        ...imageStyle
    };

    const imageSection = () => {
        return (
            <ProgressiveImg 
                imageClassName="ParagraphImage" 
                imageStyle={imageBaseStyle}
                imageKey={image.key}
                placeholderSrc={image.placeholderSrc}/>
        )
    }

    const getParagraphContentDirectionClass = () => isReverse ? 'ParagraphContentReverseDirection' : 'ParagraphContentDirection'
    const getCustomParagraphClassName = () => paragraphClassName ? paragraphClassName : ''

    return (
        <div className={`ParagraphWrapper ${getParagraphContentDirectionClass()} ${getCustomParagraphClassName()}`}>
            <div className="ParagraphContent">
                {paragraphContent}
            </div>
            <div className="ParagraphImageWrapper">
                {image ? imageSection() : null}
            </div>
        </div>
    );
}

export default Paragraph;