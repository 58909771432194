import React, { useState } from "react";
import './SharePage.css';
import Heading from "../../modules/heading/Heading";
import PhotoUploader from "../../modules/uploader/PhotoUploader";
import { Images } from "../../modules/image/Images";
import FormattedMessage from "../../modules/resolve/MessageFromatter";
import Footer from "../../modules/footer/Footer";
import LabeledInput from "../../modules/input/LabeledInput"

const SharePage = () => { 
    const [guestName, setGuestName] = useState('');

    const captureGuestName = (event) => {
        setGuestName(event && event.target ? event.target.value : '');
    }

    const animationDelayStyle = (delay) => {
        return {
            animationDelay: `${delay}ms`
        }
    }

    return (
        <div className='ContactPage'>
            <Heading 
                headingImage={Images.cats}
                headingText={"Podziel się wspomnieniami"}
                imageClassName={"SharePageHeadingImage"}/>
            <div className="PageContent">
                <div className="ContentContainer AppearLeft">
                    <span className="ContentText KeptInTheMiddle">Dziękujemy za wspólnie spędzony czas! Mamy nadzieje że bawiliście się dobrze. Jeśli tylko chcecie, podzielcie się z nami zdjęciami i filmami z tego wydarzenia. Do zobaczenia!</span>
                </div>
                <div className="ContentContainer AppearRight">
                    <LabeledInput 
                        className="GuestNameInput"
                        label={"Twój podpis (opcjonalny)"} 
                        onChange={captureGuestName}/>
                        <div className="PhotoUploaderWrapper">
                            <PhotoUploader guestNameProvider={() => guestName}/>
                        </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SharePage;