import { post } from 'aws-amplify/api';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

const isValidInput = (requestBody) => {
    return requestBody
        && requestBody.firstGuestFirstName
        && requestBody.firstGuestLastName
        && requestBody.presenceFrom
        && requestBody.presenceTo;
}

async function postConfirmation({ 
    firstGuestFirstName, 
    firstGuestLastName,
    secondGuestFirstName, 
    secondGuestLastName,
    presenceFrom, 
    presenceTo }) {
    
    const currentDate = moment().format('yyyy-MM-DD, hh:mm:ss.SSS Z');
    const requestBody = {
        confirmationId: uuid(),
        firstGuestFirstName: firstGuestFirstName,
        firstGuestLastName: firstGuestLastName,
        secondGuestFirstName: secondGuestFirstName,
        secondGuestLastName: secondGuestLastName,
        presenceFrom: presenceFrom,
        presenceTo: presenceTo,
        timestamp: currentDate
    }

    if (isValidInput(requestBody)) {
        const restOperation = post({
            apiName: 'confirmation',
            path: '/confirmations',
            options: {
                body: requestBody,
            }
        });

        const { body } = await restOperation.response;
        const response = await body.json();
    }
}

export { postConfirmation }