import React from "react";
import './PartyPage.css'
import Heading from "../../modules/heading/Heading";
import Paragraph from "../../modules/paragraph/Paragraph";
import EmbeddedMap from "../../modules/map/EmbeddedMap";
import { Icons } from "../../modules/image/Icons";
import { Images } from "../../modules/image/Images";
import FormattedMessage from "../../modules/resolve/MessageFromatter";
import Footer from "../../modules/footer/Footer";
import IconLabeled from "../../icon_labeled/IconLabeled";
import ReplacementPictogramic from "../../modules/suggestions/ReplacementPictogramic";


const PartyPage = () => {
    return (
        <div className='PartyPage'>
            <Heading 
                imageClassName="PartyPageHeadingImage"
                headingImage={Images.lnkDance}
                headingText={"Wesele"}/>
            <div className="PageContent">
                <Paragraph 
                        paragraphContent={(
                            <div className="ParagraphContentSection">
                                <span className="SubHeader Handwrite "><FormattedMessage messageKey="party.section.barn.header"/></span>
                                <span className="ContentText KeptInTheMiddle"><FormattedMessage messageKey="party.section.barn.content"/></span>
                            </div>
                        )}
                        paragraphClassName="Section DarkerBackground"
                        image={Images.barnOutside}
                        imageSize={{height: '500px'}}
                        isReverse={false}/>
                <div className="Section SlideAppearLeft">
                    <span className="SubHeader Handwrite">Lokalizacja</span>
                    <EmbeddedMap localizationKey={'barn'} />
                </div>
                <div className="Section DarkerBackground SlideAppearRight">
                    <span className="SubHeader Handwrite">Atrakcje</span>
                    <span className="SubSubHeader Handwrite ColorOrange">Parter</span>
                    <div className="IconsWrapper">
                        <IconLabeled src={Icons.dishes} label="Strefa jedzenia"/>
                        <IconLabeled src={Icons.discoBall} label="Strefa taneczna"/>
                        <IconLabeled src={Icons.cocktail} label='Mini bar "zrób to sam"'/>
                    </div>
                    <span className="SubSubHeader Handwrite ColorOrange">Piętro 1</span>
                    <div className="IconsWrapper">
                        <IconLabeled src={Icons.photoCamera} label="Fotobudka"/>
                        <IconLabeled src={Icons.book} label="Księga gości"/>
                        <IconLabeled src={Icons.sofa} label="Strefa relaksu"/>
                    </div>
                </div>
                <div className="Section SlideAppearLeft">
                    <span className="SubHeader Handwrite">Sugestie prezentowe</span>
                    <ReplacementPictogramic
                        className="SuggestionsComponent"
                        replacedSrc={Icons.flowers} 
                        replacementsSrc={[
                            Icons.coffeeBag,
                            Icons.lotto,
                            Icons.petFood
                        ]}/>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PartyPage;