import React, { useContext } from "react";
import { resolveValue } from "../../modules/resolve/ValueResolver";
import './Schedule.css'
import { State } from "../state/State";
import moment from "moment";

const Schedule = ({ scheduleKey }) => {
    const state = useContext(State);
    const dates = resolveValue(state, scheduleKey, [])
        .map(item => {
            return {
                dateTime: moment(item.dateTime), 
                description: item.description
            }
        });

    const groupByDay = () => {
        const grouped = new Map();
        for (let index = 0; index < dates.length; index++) {
            const element = dates[index];
            const date = element.dateTime.format('DD.MM');
            const time = element.dateTime.format('HH:mm');
            const value = { time: time, description: element.description };
            if (grouped.get(date) === undefined) {
                grouped.set(date, []);
            }
            grouped.set(date, [...grouped.get(date), value]);
        }
        return grouped;
    }

    const renderTime = (time, description) => {
        return (
            <div key={time} className='TimeRow'>
                <span className='ContentText TimeStyling'>{time}</span>
                <span className='ContentText DescriptionStyling'>{description}</span>
            </div>
        );
    }

    const renderGroup = (date, values) => {
        return (
            <div key={date} className='DateRow'>
                <div className='DateSection'>
                    <span className='ContentText ColorOrange'>{date}</span>
                </div>
                <div className='VerticalSeparator'></div>
                <div className='TimesSection'>
                    {values.map(value => renderTime(value.time, value.description))}
                </div>
            </div>
        );
    }

    return (
        <div key={dates}>
            {Array.from(groupByDay(dates)).map(([key,value]) => renderGroup(key, value))}
        </div>
    );
}

export default Schedule;