import React from "react";
import './WeddingPage.css'
import Heading from "../../modules/heading/Heading";
import Paragraph from "../../modules/paragraph/Paragraph";
import EmbeddedMap from "../../modules/map/EmbeddedMap";
import FormattedMessage from "../../modules/resolve/MessageFromatter";
import { Images } from "../../modules/image/Images";
import Footer from "../../modules/footer/Footer";


const WeddingPage = () => {
    return (
        <div className="WeddingPage">
            <Heading 
                imageClassName="WeddingPageHeadingImage"
                headingImage={Images.lnkWhiteWall}
                headingText={"Ceremonia"}/>
            <div className="PageContent">
                <Paragraph 
                    paragraphContent={(
                        <div className="ParagraphContentSection">
                            <span className="SubHeader Handwrite "><FormattedMessage messageKey="wedding.section.church.header"/></span>
                            <span className="ContentText KeptInTheMiddle"><FormattedMessage messageKey="wedding.section.church.content"/></span>
                        </div>
                    )}
                    paragraphClassName="Section DarkerBackground"
                    image={Images.church}
                    isReverse={false}
                    imageSize={{
                        height: '450px'
                    }}/>
                <div className="Section SlideAppearLeft">
                    <span className="SubHeader Handwrite">Lokalizacja</span>
                    <EmbeddedMap className="Padding5px" localizationKey={'church'} />
                </div>
                <div className="Section DarkerBackground SlideAppearRight">
                    <span className="SubHeader Handwrite"><FormattedMessage messageKey="wedding.section.access.header"/></span>
                    <span className="ContentText KeptInTheMiddle"><FormattedMessage messageKey="wedding.section.access.content"/></span>
                    <span className="ContentText KeptInTheMiddle"><FormattedMessage messageKey="wedding.section.access.content.pt2"/></span>
                </div>
                <div className="Section SlideAppearLeft">
                    <span className="SubHeader Handwrite"><FormattedMessage messageKey="wedding.section.afterwards.header"/></span>
                    <span className="ContentText KeptInTheMiddle"><FormattedMessage messageKey="wedding.section.afterwards.content"/></span>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default WeddingPage;