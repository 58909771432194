import React, { useState } from "react";
import './RsvpPage.css'
import Heading from "../../modules/heading/Heading";
import LabeledInput from "../../modules/input/LabeledInput";
import Button from "../../modules/button/Button";
import { Images } from "../../modules/image/Images";
import { postConfirmation } from "./ConfirmationService";
import TickIcon from "../../images/tick-icon.svg"
import Footer from "../../modules/footer/Footer";

const RsvpPage = () => {
    const defaultDateConstraints = {
        min: "2024-10-11",
        max: "2024-10-14"
    }

    const [saveStatus, setSaveStatus] = useState({
        isLoading: false,
        success: false,
        failure: false,
        error: undefined
    });
    const [dynamicMinDate, setDynamicMinDate] = useState(defaultDateConstraints.min);
    const [dynamicMaxDate, setDynamicMaxDate] = useState(defaultDateConstraints.max);

    const fromDateConstraints = () => {
        return {
            min: defaultDateConstraints.min,
            max: dynamicMaxDate
        };
    }

    const toDateConstraints = () => {
        return {
            min: dynamicMinDate,
            max: defaultDateConstraints.max
        };
    }

    const animationDelayStyle = (delay) => {
        return {
            animationDelay: `${delay}ms`
        }
    }

    const submitConfirmation = (event) => {
        event.preventDefault()

        const form = event.target;
        const formData = new FormData(form);

        const formJson = Object.fromEntries(formData.entries());
        console.log(formJson);
        setSaveStatus({
            isLoading: true,
            success: false,
            failure: false,
            error: undefined
        })
        postConfirmation(formJson)
            .then(() => {
                setSaveStatus({
                    isLoading: false,
                    success: true,
                    failure: false,
                    error: undefined
                })
            }).catch((e) => {
                setSaveStatus({
                    isLoading: false,
                    success: false,
                    failure: true,
                    error: e
                })
            });
    };

    const renderError = () => {
        return (
            <div className="ErrorWrapper">
                <span className="ErrorText">Ups! Coś poszło nie tak. Spróbuj ponownie później.</span>
            </div>
        );
    }

    const renderForm = () => {
        return (
            <form 
                className="ConfirmationForm"
                onSubmit={submitConfirmation} 
                method="post">
                <div className="InputGroup AppearLeft" style={animationDelayStyle(500)}>
                    <span className="GroupHeader">Weselnik pierwszy</span>
                    <LabeledInput 
                        className="Input" 
                        name="firstGuestFirstName" 
                        isRequired={true}
                        label="Imię"/>
                    <LabeledInput 
                        className="Input" 
                        name="firstGuestLastName" 
                        isRequired={true}
                        label="Nazwisko"/>
                </div>
                <div className="InputGroup AppearLeft" style={animationDelayStyle(750)}>
                    <span className="GroupHeader">Weselnik drugi</span>
                    <LabeledInput 
                        className="Input" 
                        name="secondGuestFirstName" 
                        label="Imię"/>
                    <LabeledInput 
                        className="Input" 
                        name="secondGuestLastName" 
                        label="Nazwisko"/>
                </div>
                <div className="InputGroup AppearLeft" style={animationDelayStyle(1000)}>
                    <span className="GroupHeader">Termin</span>
                    <LabeledInput 
                        className="Input" 
                        name="presenceFrom" 
                        isRequired={true}
                        onChange={(e) => e && e.target && e.target.value && setDynamicMinDate(e.target.value)}
                        type="date" 
                        label="Przybycie" 
                        additionalProperties={{...fromDateConstraints()}}/>
                    <LabeledInput 
                        className="Input" 
                        name="presenceTo" 
                        isRequired={true}
                        onChange={(e) => e && e.target && e.target.value && setDynamicMaxDate(e.target.value)}
                        type="date" 
                        label="Wymeldowanie" 
                        additionalProperties={{...toDateConstraints()}}/>
                </div>
                <div className="Gap"/>
                {saveStatus.failure ? renderError() : null}
                <Button 
                    className="ConfirmationButton AppearBottom" 
                    style={animationDelayStyle(1250)} 
                    isEnabled={!saveStatus.isLoading}
                    type="submit">Potwierdź</Button>
            </form>
        );
    }

    const renderThanks = () => {
        return (
            <div className="ThanksWrapper AppearBottom">
                <img src={TickIcon} className="ThanksIcon"/>
                <span className="ThanksText">Poszło! Dziękujemy za potwierdzenie!</span>
            </div>
        );
    }

    return (
        <div className="RsvpPage">
            <Heading
                headingImage={Images.barnInside}
                headingText="Potwierdzenie obecności"
                />
            <div className="PageContent">
                {saveStatus.success ? renderThanks() : renderForm()}
            </div>
            <Footer/>
        </div>
    );
}

export default RsvpPage;