import './Separator.css'

const Separator = ({ height }) => {
    return (
        <div className="Separator" style={{height: height}}>

        </div>
    );
}

export default Separator;