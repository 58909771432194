import React from "react";
import "./Heading.css"
import ProgressiveImg from "../image/ProgressiveImg";

const Heading = ({headingImage, headingText, imageClassName}) => {

    const renderImage = () => {
        return (
            <ProgressiveImg 
                imageClassName={`HeadingImage ${imageClassName ? imageClassName : ''}`} 
                imageKey={headingImage.key}
                placeholderSrc={headingImage.placeholderSrc}/>
        )
    }

    return (
        <div>
            <div className="HeadingContainer">
                {headingImage ? renderImage() : null}
            </div>
            <div className="HeadingContent">
                <span className="HeadingText">{headingText}</span>
            </div>
        </div>
    );
}

export default Heading;