import React from "react";
import './ContactPage.css';
import Heading from "../../modules/heading/Heading";
import { Images } from "../../modules/image/Images";
import FormattedMessage from "../../modules/resolve/MessageFromatter";
import Footer from "../../modules/footer/Footer";

const ContactPage = () => {   
    const animationDelayStyle = (delay) => {
        return {
            animationDelay: `${delay}ms`
        }
    }

    return (
        <div className='ContactPage'>
            <Heading 
                headingImage={Images.contactPageHeading}
                headingText={"Kontakt"}
                imageClassName={"ContactPageHeadingImage"}/>
            <div className="PageContent">
                <span className="CenterText TextStyled ContactsHelloText AppearLeft" style={animationDelayStyle(250)}>
                    <FormattedMessage messageKey='contact.paragraph.1'/>
                </span>
                <div className="ContactRow AppearLeft" style={animationDelayStyle(500)}>
                    <span className="TextStyled"><FormattedMessage messageKey='contact.jakub.name'/>: (+48) <FormattedMessage messageKey='contact.jakub.number'/></span>
                </div>
                <div className="ContactRow AppearLeft" style={animationDelayStyle(750)}>
                    <span className="TextStyled"><FormattedMessage messageKey='contact.karolina.name'/>: (+48) <FormattedMessage messageKey='contact.karolina.number'/></span>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ContactPage;