import { Link } from 'react-router-dom';
import './Footer.css'

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo({ 
          top: 0,  
          behavior: 'smooth'
        }); 
    }

    const renderLinkTo = (to, label, isEnabled = true) => {
        return isEnabled 
            ? (<Link className="LinkStyling" to={to} onClick={scrollToTop}><span>{label}</span></Link>)
            : null;
    }

    return (
        <div className="Footer">
            <div className="FooterContent">
                <span className='FooterText'>Karolina & Kuba</span>
                <span className='FooterText YearText'>2024</span>
                <div className='LinksWrapper'>
                    {renderLinkTo("/", "Strona główna")}
                    {renderLinkTo("/accomodation", "Zakwaterowanie")}
                    {renderLinkTo("/wedding", "Ceremonia")}
                    {renderLinkTo("/party", "Wesele")}
                    {renderLinkTo("/rsvp", "RSVP")}
                    {renderLinkTo("/contact", "Kontakt")}
                </div>
            </div>
        </div>
    );
}

export default Footer;