import React from "react";
import { Authenticator, Autocomplete } from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import RootPage from "./pages/RootPage";
import './App.css'
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';
Amplify.configure(awsExports);
I18n.putVocabularies(translations);
I18n.setLanguage('pl');

I18n.putVocabularies({
  pl: {
    'Sign in': 'Wejdź',
    'Incorrect username or password.': 'Hasło niepoprawne',
    'password is required to signIn': 'Hasło jest wymagane, znajdziesz je w zaproszeniu ;)'
  },
  en: {
    'Sign in': 'Authorize',
  },
});

const components = {
    SignIn: {
        Footer() {
            return null;
        }
    }
}

const formFields = {
    signIn: {
        username: {
            label: "Użytkownik",
            defaultValue: "Weselnik",
            isReadOnly: true
        },
        password: {
            label: "Hasło (znajduje się na zaproszeniu)",
            placeholder: "Hasło",
            autocomplete: "off"
        }
    }
}

export default function App() {
    return (
        <Authenticator 
            className="AuthContainer"
            formFields={formFields}
            hideSignUp={true}
            components={components}>
                <RootPage />
        </Authenticator>
    )
}