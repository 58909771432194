import { useState, useEffect } from "react";
import { retrieveS3FileUrl } from '../resolve/S3FileRetriever'
import "./ProgressiveImg.css"


const ProgressiveImg = ({ placeholderSrc, imageKey, imageProps = {}, imageStyle = {}, imageClassName }) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc);
  
  useEffect(() => {
        if (imageKey) {
          retrieveS3FileUrl({
                filePath: imageKey, 
                onFetch: loadImage
            });
        }
  }, []);

  const loadImage = (src) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      setImgSrc(src)
    }
  }

  return (
    <img
      className={`ProgressiveImageBlured ${imageClassName}`}
      style={imageStyle}
      {...{ src: imgSrc, ...imageProps }}
    />
  );
};
export default ProgressiveImg;