import React, { useContext } from "react";
import { resolveValue } from "../../modules/resolve/ValueResolver";
import "./EmbeddedMap.css"
import { State } from "../state/State";

const EmbeddedMap = ({localizationKey, className}) => {
    const state = useContext(State);
    const googleMaps = (key) => `https://www.google.com/maps/embed?pb=${resolveValue(state, key, '')}`;

    return (
        <iframe src={googleMaps(`localization.${localizationKey}`)}
            className={`MapFrame ${className ? className : ''}`}
            style={{border: 0}}
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade">

        </iframe>
    );
}

export default EmbeddedMap;