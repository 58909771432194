import React, { Fragment, useState } from 'react'
import './Overlay.css'
import hamburgerIcon from './../../images/hamburger-icon.svg'
import Drawer from './drawer/Drawer';
import { Outlet } from 'react-router-dom';

const Overlay = () => {
    const [isOverlayVisible, setOverlayVisible] = useState(false);
  
    const toggleOverlay = () => {
      setOverlayVisible(!isOverlayVisible);
    };

    return (
      <Fragment>
        <Outlet />
        <button className='drawer-floating-button' onClick={toggleOverlay}>
            <img src={hamburgerIcon} className='drawer-floating-button-image'/>
        </button>
        <Drawer isVisible={isOverlayVisible} onClickOutside={toggleOverlay}/>
      </Fragment>
    );
}

export default Overlay;