import { getUrl, downloadData } from 'aws-amplify/storage';

async function retrieveS3FileUrl({ filePath, onFetch }) {
    getUrl({
        path: `${filePath}`
    }).then(result => {
        if (onFetch)
            onFetch(result.url)
    }).catch(error => {
        console.log('Error ', error);
    })
} 

async function retrieveS3FileAsText({ filePath, onFetch, onError }) {
    try {
        const result = await downloadData({
            path: `${filePath}`,
            options: {
                validateObjectExistence: true,
                expiresIn: 20,
                cacheControl: 'no-cache'
            }
        }).result

        if (onFetch && result) {
            const text = await result.body.text();
            onFetch(text)
        }
    } catch(error) {
        console.log('Error ', error);
        if (onError)
            onError();
    }
} 

export { retrieveS3FileUrl, retrieveS3FileAsText };