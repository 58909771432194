import React from "react";
import './PhotoUploader';
import { FileUploader } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';
import moment from "moment";

const PhotoUploader = ({guestNameProvider}) => {
    const [language, setLanguage] = React.useState('pl');
    const maxNumOfFiles = 300;
    const maxFileSizeInBytes = 100000000;
    const guestNameCharsRegex = new RegExp("([a-zA-Z])");

    const getPolishWordFileForm = (count) => {
      if ((count >= 10 && count < 20) || count % 10 == 0 || count % 10 > 4)
          return 'plików'
      if (count == 1)
          return 'plik'
      return 'pliki'
    };

    const dictionary = {
        // use default strings for english
        en: null,
        pl: {
          getFilesUploadedText(count) {
            return `Wysłano  ${count} ${getPolishWordFileForm(count)}`;
          },
          getFileSizeErrorText(sizeText) {
            return `Plik przekracza maksymalny rozmiar ${sizeText}`;
          },
          getSelectedFilesText(count) {
            return `Wybrano ${count} ${getPolishWordFileForm(count)}`;
          },
          getRemainingFilesText(count) {
            return `Pozostało ${count} ${getPolishWordFileForm(count)}`;
          },
          getUploadingText(percentage) {
            return `Przesyłanie${percentage > 0 ? `: ${percentage}%` : ''}`;
          },
          getUploadButtonText(count) {
            return `Prześlij ${count} ${getPolishWordFileForm(count)}`;
          },
          getMaxFilesErrorText(count) {
            return `Błąd: ${count} ${
                getPolishWordFileForm(count)
            } przekracza maksymalną liczbę ${maxNumOfFiles}`;
          },
          getErrorText(message) {
            return message;
          },
          doneButtonText: 'Ukończono',
          clearAllButtonText: 'Usuń wszystkie',
          extensionNotAllowedText: 'Plik niedozwolony',
          browseFilesText: 'Wybierz pliki',
          dropFilesText: 'Przeciągnij pliki tutaj',
          pauseButtonText: 'Wstrzymaj',
          resumeButtonText: 'Wznów',
          uploadSuccessfulText: 'Przesyłanie zakończone',
          getPausedText(percentage) {
            return `Wstrzymano: ${percentage}%`;
          },
        },
      };

    const generateUniqueDirectoryName = () => moment().format('YYYYMMDDHHmmsss');

    const attemptToSimplifyCharacter = (char) => {
        switch(char) {
            case 'ą': return 'a';
            case 'ę': return 'e';
            case 'ó': return 'o';
            case 'ł': return 'l';
            case 'ś': return 's';
            case 'ź': return 'z';
            case 'ż': return 'z';
            case 'Ą': return 'A';
            case 'Ę': return 'E';
            case 'Ó': return 'O';
            case 'Ł': return 'L';
            case 'Ś': return 'S';
            case 'Ź': return 'Z';
            case 'Ż': return 'Z';
            case ' ': return '_';
            case '-': return '_';
            case '_': return '_';
        }

        return '';
    }

    const getGuestName = () => {
        const defaultGuestName = 'guest';
        const providedName = guestNameProvider && guestNameProvider() 
            ? guestNameProvider().replaceAll('_', ' ').replaceAll('-',' ').trim() 
            : '';

        let clearedName = '';
        for (let i = 0; i < providedName.length && clearedName.length < 30 ; i++) {
            if (guestNameCharsRegex.test(providedName[i])) {
                clearedName += providedName[i];
            } else {
                clearedName += attemptToSimplifyCharacter(providedName[i]);
            }
        }

        return clearedName.length == 0 ? defaultGuestName : clearedName;
    }

    return (
        <FileUploader
            acceptedFileTypes={['image/*', 'video/*']}
            autoUpload={false}
            path={({ identityId }) => `protected/${identityId}/shared/${getGuestName()}/${generateUniqueDirectoryName()}/`}
            maxFileCount={maxNumOfFiles}
            maxFileSize={maxFileSizeInBytes}
            isResumable
            displayText={dictionary[language]}
            />
    )
}

export default PhotoUploader;