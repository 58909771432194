import AccomodationPlaceholder from '../../images/accomodation_placeholder.jpg'
import CatsPlaceholder from '../../images/cats_placeholder.jpg'
import ChurchPlaceholder from '../../images/church_outside_placeholder.jpg'
import ChurchEnterancePlaceholder from '../../images/church_enterance_placeholder.jpg'
import ContactPlaceholder from '../../images/contact_placeholder.jpg'
import BarnPlaceholder from '../../images/barn_placeholder.jpg'
import BarnOutsidePlaceholder from '../../images/barn_outside_placeholder.jpg'
import BarnInsidePlaceholder from '../../images/barn_inside_placeholder.jpeg'
import FlowersAndLemonsPlaceholder from '../../images/flowers_and_lemons_placeholder.jpg'
import LnKCats01Placeholder from '../../images/lnk_cats_01_placeholder.jpg'
import LnKDancePlaceholder from '../../images/lnk_dance_placeholder.jpg'
import LnKWhiteWallPlaceholder from '../../images/lnk_white_wall_placeholder.jpg'

export const Images = {
    accomodation: {
        key: 'protected/images/accomodation/accomodation.jpg',
        placeholderSrc: AccomodationPlaceholder
    },
    barn: {
        key: 'protected/images/party/barn.jpg',
        placeholderSrc: BarnPlaceholder
    },
    barnOutside: {
        key: 'protected/images/party/barn_outside.jpg',
        placeholderSrc: BarnOutsidePlaceholder
    },
    barnInside: {
        key: 'protected/images/party/barn_inside.jpeg',
        placeholderSrc: BarnInsidePlaceholder
    },
    cats: {
        key: 'protected/images/us/cats.jpg',
        placeholderSrc: CatsPlaceholder
    },
    church: {
        key: 'protected/images/church/church_outside.jpg',
        placeholderSrc: ChurchPlaceholder
    },
    churchEnterance: {
        key: 'protected/images/church/church_enterance.jpg',
        placeholderSrc: ChurchEnterancePlaceholder
    },
    contactPageHeading: {
        key: 'protected/images/us/contact.jpeg',
        placeholderSrc: ContactPlaceholder
    },
    flowersAndLemons: {
        key: 'protected/images/us/flowers_and_lemons.jpeg',
        placeholderSrc: FlowersAndLemonsPlaceholder
    },
    lnkCats01: {
        key: 'protected/images/us/lnk_cats_01.jpeg',
        placeholderSrc: LnKCats01Placeholder
    },
    lnkDance: {
        key: 'protected/images/us/lnk_dance.jpg',
        placeholderSrc: LnKDancePlaceholder
    },
    lnkWhiteWall: {
        key: 'protected/images/us/lnk_white_wall.jpeg',
        placeholderSrc: LnKWhiteWallPlaceholder
    }
}