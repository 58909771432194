import { useState } from 'react';
import './LabeledInput.css'

const LabeledInput = ({
        id, 
        name, 
        isRequired,
        label, 
        type, 
        placeholder, 
        className, 
        onKeyDown,
        onChange, 
        additionalProperties}) => {
    const [isValid, setIsValid] = useState(true);

    const inputClassName = () => `Field ${!isValid ? "FieldFailedValidation" : ""}`;

    const markerIfRequired = () => {
        return isRequired ? (<span>*</span>) : null;
    }

    const validate = (event) => {
        const value = event && event.target ? event.target.value : null;
        const isNotEmptyOrNotRequired = !isRequired || value;
        setIsValid(isNotEmptyOrNotRequired);
    }

    return (
        <div className={`Wrapper ${className}`}>
            <span className="Label">{label}{markerIfRequired()}</span>
            <input className={inputClassName()}
                id={id}
                name={name}
                required={isRequired}
                type={type}
                placeholder={placeholder} 
                onKeyDown={onKeyDown}
                onChange={(event) => {
                    validate(event);
                    if(onChange)
                        onChange(event);
                }}
                {...additionalProperties}
                />
        </div>
    );
}

export default LabeledInput;