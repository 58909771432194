import './ReplacementPictogramic.css'
import { Icons } from '../image/Icons'


const ReplacementPictogramic = ({className, replacedSrc, replacementsSrc}) => {
    return (
        <div className={`ReplacementPictogramicWrapper ${className ? className : ''}`}>
            <div className='ReplacedWrapper'>
                <img className='Pictogram' src={replacedSrc} />
                <img className='Pictogram AbsoluteBegin TintOrange' src={Icons.disallowed}/>
            </div>
            <img className='Pictogram Arrow' src={Icons.arrow} />
            <div className='ReplacementsWrapper'>
                {replacementsSrc.map(replacementSrc => (<img className='Pictogram' src={replacementSrc} />))}
            </div>
        </div>
    );
}

export default ReplacementPictogramic;