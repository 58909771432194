import React, { useEffect, useState } from 'react';
import {
    RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import HomePage from './home/HomePage';
import AccomodationPage from './accomodation/AccomodationPage';
import Overlay from '../modules/overlay/Overlay'
import WeddingPage from './wedding/WeddingPage';
import PartyPage from './party/PartyPage';
import Spinner from '../modules/spinner/Spinner'
import { State, defaultState, statePreparationStatus } from './../modules/state/State';
import { loadResources } from '../modules/resolve/ResourcesLoader';
import ContactPage from './contact/ContactPage';
import SharePage from './share/SharePage';
import RsvpPage from './rsvp/RsvpPage';

const router = createBrowserRouter([
    {
      path: "/",
      element: <Overlay/>,
      children: [
        {
          path:"/", 
          element: <HomePage/>
        },
        {
          path:"/accomodation", 
          element: <AccomodationPage/>
        },
        {
          path:"/wedding", 
          element: <WeddingPage/>
        },
        {
          path:"/party", 
          element: <PartyPage/>
        },
        {
          path:"/rsvp", 
          element: <RsvpPage/>
        },
        {
          path:"/share", 
          element: <SharePage/>
        },
        {
          path:"/contact", 
          element: <ContactPage/>
        }
      ]
    },
  ]);

export default function RootPage() {
    const [state, setState] = useState({...defaultState})
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        loadResources(state, () => {setIsReady(true)})
    }, [])

    return (
        <div className='RootPage'>
            <State.Provider value={state} key={state.translationsLoaded && state.valuesLoaded}>
                {state.translationsLoaded && state.valuesLoaded
                    ? <RouterProvider router={router} /> 
                    : <Spinner />}
            </State.Provider>
        </div> 
    )
}