import './Spinner.css'
import SpinnerImage from '../../images/spinner.svg'

const Spinner = () => {
    return (
        <div className="SpinnerWrapper">
            <img src={SpinnerImage} className="Spinner Spinning"/>
        </div>
    );
}

export default Spinner;